import { useState } from "react";
import { Button, Form, Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { IUser } from "../../models/user";
import { ISpAjaxUser } from "../../ducks/ajax/model";
import { AjaxService } from "../../ducks/ajax/ajax.service";
import { HubLocationService } from "../../ducks/hub-location/hub.location";
import { useParams } from "react-router-dom";
import { IClientType } from "../../components/clients/create.client";

interface IAuthProps {
  login: string;
}

interface ICreateSpaceProps {
  users: ISpAjaxUser[];
}

interface IBindUserSpace {
  ajaxService: AjaxService;
  hubLocationService: HubLocationService;
}

const deps: IBindUserSpace = {
  ajaxService: new AjaxService(),
  hubLocationService: new HubLocationService(),
};

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: IAuthProps) => void;
  onCancel: () => void;
}

export const BindUserInSpace = (props: ICreateSpaceProps) => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user as IUser);

  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const BindHubInSpace: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
  }) => {
    const [USER_HUBS, SET_USER_HUBS] = useState([]);
    const [loadingHub, setLoadingHub] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const autorizeAjaxUser = async (login: any) => {
      if (!login) return;
      SET_USER_HUBS([]);

      try {
        const auth = { login };
        const bindedHubs = await deps.ajaxService.bindedHubs(auth);

        if (bindedHubs.length) {
          setLoadingHub(true);
          const userHubs = await getBindedHubDetail(auth, bindedHubs);
          SET_USER_HUBS(userHubs);
          setLoadingHub(false);
        }
      } catch (e) {
        console.log("E:", e);
      }
    };

    const getBindedHubDetail = async (auth: IAuthProps, bindedHubs: any[]) => {
      return await deps.ajaxService.bindedHubDetail(auth, bindedHubs);
    };

    return (
      <Modal
        open={open}
        title="Подключение хаба"
        okText="Добавить"
        cancelText="Отменить"
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              setConfirmLoading(true);
              onCreate(values);

              setTimeout(() => {
                setConfirmLoading(false);
              }, 1000);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="login"
            label="Пользователь"
            rules={[
              {
                required: true,
                message: "Пользователь объязателен!",
              },
            ]}
          >
            <Select
              allowClear
              onChange={autorizeAjaxUser}
              onClear={() => SET_USER_HUBS([])}
              options={props?.users?.map((user) => {
                return {
                  ...user,
                  value: user.login,
                  label: user.login,
                };
              })}
            />
          </Form.Item>

          <Form.Item
            name="hubId"
            label="Хаб"
            rules={[
              {
                required: true,
                message: "Хаб объязателен!",
              },
            ]}
          >
            <Select
              allowClear
              disabled={!USER_HUBS.length}
              loading={loadingHub}
              // onChange={}
              options={USER_HUBS.map((hub: any) => {
                return {
                  value: hub.id,
                  label: hub.name,
                };
              })}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const bindAjaxUser = async (value: any) => {
    if (value.hubId) {
      const isBinded = await deps.hubLocationService.verifyIfBindedAjaxUser(
        value.hubId
      );

      if (isBinded) {
        alert("Уже добавлен");

        setOpen(false);

        return;
      }

      const payload = {
        hubId: value.hubId,
        userId: value.login,
        spaceId: id,
        type: IClientType.Cloud,
        zones: [{}],
      };

      await deps.hubLocationService.bindAjaxUserInLocation(payload);
      setOpen(false);
      window.location.reload();
    }
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Привязать хаб
      </Button>

      <BindHubInSpace
        open={open}
        onCreate={bindAjaxUser}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
};
